import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class MCUService {
    url = protectedResources.starhealthapi.endpoint;
    constructor(private http: HttpClient) { }

    getAllMember(search: string) {

        return this.http.get<any>(`${this.url}/mcu/member?search=${search}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    downloadTemplateMcu(): any {

        return this.http.get(`${this.url}/mcu/download`, { responseType: 'blob' })
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getMCUMeYear() {

        return this.http.get<any>(`${this.url}/mcu/me/year`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getMCUMeHeader(year:number) {

        return this.http.get<any>(`${this.url}/mcu/me/header?year=${year}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getMCUMeDetail(year:number) {

        return this.http.get<any>(`${this.url}/mcu/me/detail?year=${year}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getMCUMeChart() {

        return this.http.get<any>(`${this.url}/mcu/me/chart`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getAllMCUWithPaging(year:number, search: string, order: string, page: number, max: number) {

        return this.http.get<any>(`${this.url}/mcu/search?year=${year}&search=${search}&order=${order}&page=${page}&max=${max}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }
    
    UploadMcu(McuFile: File) {
        let formData: FormData = new FormData();
        formData.append('file', McuFile);


        return this.http.post<any>(`${this.url}/mcu/upload`, formData)
            .pipe(map((res: string) => {
                return res;
            }));
    }
}